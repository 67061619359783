import React from 'react';
import Layout from "../components/layout"
import {
    makeStyles,
} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(5, 0, 5),
        // position: 'fixed',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 2),
        }
    },
    title: {
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '2.2vw',
        fontWeight: 'bold',
        color: 'seashell',
        textShadow: '1px 1px 150px #080705',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '4vh',
            margin: '2rem 0 0 0 ',
        }
    },
    paragraph: {
        fontFamily: 'Helvetica Neue, sans-serif, serif',
        fontSize: '1.2rem',
        lineHeight: '2rem',
        textAlign: 'center',
        color: '#f2f2f2',
        [theme.breakpoints.down('sm')]: {
            margin: '0rem 0rem 1rem 0rem',
            fontSize: '3vw',
            lineHeight: '4vw',
        },
    },
    icon: {
        color: 'white',
        fontSize: '1.5rem',
        width:'20rem',
        margin: theme.spacing(3),
        border: '1px solid',
        '&:hover': {
            color: 'goldenrod',
            background: 'rgba(0,0,0,0) 0%',
        },
    },
    formGroup: {
        textAlign: 'center',
        display:'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin:'2rem',
        [theme.breakpoints.down('sm')]: {
            margin:'0',
        },
    },
    input:{
        width:'65%',
        marginTop: '1rem',
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
    },
    mailLink:{
        textDecoration: 'none'
    }
}));


export default function CustomizedInputs() {
    const classes = useStyles();

    return (
        <Layout bgImage={false} siteTitle={'Lesson'}>
            <Container maxWidth="lg" component="main" className={classes.root}>
                <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                    Contact
                </Typography>
                <Grid container className={classes.root}>

                    <Grid item xs={12} md={12}>
                        <Typography className={classes.paragraph}>
                            チケットのご予約、各種お問い合わせはこちらからお願い致します。
                        </Typography>
                        <Typography className={classes.paragraph}>
                            ※頂いた内容への返信は「michellehouse2014@gmail.com」にて行っております。<br/>
                            お問い合わせの際には上記アドレスからのメールを受信できるよう設定をお願い致します。
                        </Typography>

                        <div className={classes.formGroup}>
                            <a className={classes.mailLink} href="mailto:michellehouse2014@gmail.com?subject=%96%e2%82%a2%8d%87%82%ed%82%b9">
                            <Button variant="outlined" className={classes.icon} type="submit">
                                お問い合わせはこちらまで
                            </Button>
                            </a>
                        </div>

                    </Grid>
                </Grid>
            </Container>
        </Layout>
    );
}
